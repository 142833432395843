import React, { Component } from "react"
import { Container, NavItem, NavLink, Row, Col, Spinner, FormGroup, Label, Input } from "reactstrap"
import { withRouter, Link } from "react-router-dom"
import MetaTags from 'react-meta-tags'
import Carousel from "react-multi-carousel"
import { useDispatch, connect } from "react-redux";
import _ from 'lodash'
import SubscribedCard from '../CommonComponents/SubscribedCard'
import urlSocket from '../../helpers/urlSocket'
import './CSS/dashboard.scss'
import BannerSwiperLG from "../Common/Carousel/components/banner-swiper";
import BannerSwiperMD from "../Common/Carousel/components/banner-swiper-md";
import HomeCourseCard from "../CommonComponents/CourseCard";
import MoreViewCard from "../CommonComponents/MoreViewCard"

import img1 from "../../assets/images/bg/bg_2.png"

import { getCourseListAPI, getSubscriberCourseListAPI } from '../cartList/CartListAPI'
import { setCartCourseListLength } from '../../store/layout/actions'
import ShareButtons from "common/ShareButton"
import _debounce from 'lodash/debounce';




const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
    },
    laptop: {
        breakpoint: { max: 3000, min: 1000 },
        items: 4,
    },
    tablet: {
        breakpoint: { max: 1000, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    }
}

class Dashboard extends Component {
    myRef = React.createRef()

    constructor(props) {
        super(props)



        this.state = {
            isLogedUser: false,
            myip: null,
            mycountry: null,
            courseList: [],
            User_id: '',
            specialized_courseList: [],
            unSpecialized_courseList: [],
            cardCourseList: [],
            subscribedCourses: [],
            masterCourses: [],
            su_courses: [],
            catData: [],
            gridView: "block",
            axiosTimeout: false,
            selected_course_filter: '0',
            artist_filter_type: "All",
            artistList: [],
            btn_loader: false,
            categoryList: [],
            searchTerm: [],
            courseType: '',
            artistType: '',
            categoryType: '',
            courseTypeId:'',
            addingCart : false,
            removeCart : false
        }
        this.categoryList = React.createRef()
        this.artistList = React.createRef()
        this.courseType = React.createRef()

            // this.apiCount =0




    }


    async componentDidMount() {
        this.getSessionDatas()
        this.checkLogedUser()
        this.getSubscribedCourses()
        this.getSUCourses()
    }



    getSessionDatas = () => {
        try {
            var getconfsession = JSON.parse(sessionStorage.getItem("getconfsession"))
            let { baseurl, self_regn } = getconfsession
            this.setState({ baseurl, self_regn: self_regn })

        } catch (error) {
            this.getConfig()
        }
    }

    getConfig = () => {
        try {
            urlSocket.post('ctlibmgt/getconf')
                .then((res) => {
                    let secret_key = res.data.data.secret_key
                    this.setState({ secret_key })
                    let data = res.data.data
                    let { baseurl } = data
                    this.setState({ baseurl })
                    sessionStorage.setItem("getconfsession", JSON.stringify(data))

                })
                .catch((error) => {

                })
        } catch (error) {

        }
    }


    checkLogedUser = () => {
        var isLogedUser = JSON.parse(sessionStorage.getItem("authUser"))
        if (isLogedUser === null) {
            this.setState({ isLogedUser: false })
        } else {
            this.setState({ isLogedUser: true })
        }
        this.setState({ isLogedUser: isLogedUser })
    }

    //---- Subscribed and Unsubscribed courses ----
    getSUCourses = () => {

        this.setState({
            axiosTimeout: false
        })

        var subscriber_info = {}
        if (sessionStorage.getItem("authUser")) {
            subscriber_info = JSON.parse(sessionStorage.getItem("authUser"))
        }

        try {
            urlSocket.post('mngsubcrs/retrive-all-courses', { subscriber_id: subscriber_info._id })
                .then((response) => {
                    // console.log(response,'response')
                    if (response.data.response_code === 500) {
                        let courseList = response.data.data
                        let catData = response.data.final_data
                        let cartCountLength = response.data.cart_count
                        var artistList = response.data.artist_list
                        var categoryList = response.data.category
                        if (courseList.length > 0) {
                            this.setState({ catData, su_courses: courseList, dataloaded: true, artistList, btn_loader: false, categoryList,no_course_available: false })
                            this.props.dispatch(setCartCourseListLength(cartCountLength));
                        }
                    }
                })
                .catch((error) => {
                    this.setState({
                        axiosTimeout: true
                    })
                })
        } catch (error) {
            this.setState({
                axiosTimeout: true
            })
        }
    }



    getSubscribedCourses = async () => {

        try {
            var courseList = await getSubscriberCourseListAPI()
            if (courseList) {

                this.setState({ subscribedCourses: courseList.data.data })
            }

        } catch (error) {

        }
    }

    updateLocation = (mycountry, country, exdays) => {
        const d = new Date()
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
        let expires = "expires=" + d.toUTCString()
        document.cookie = mycountry + "=" + country + ";" + expires + ";path=/"
    }


    courseDetails = (data) => {
        let name_of_the_course = data.name_of_the_course.trim().replace(/[^A-Z0-9]\s+/ig, "").replaceAll(' ', '-');
        localStorage.setItem("course_id", JSON.stringify(data.course_info_id))

        let courseId = data.course_info_id !== undefined ? data.course_info_id : data._id

        this.props.history.push({
            pathname: '/course/CourseDetails',
            search: '?' + courseId + '?' + name_of_the_course.toLowerCase(),
        });
        if (data.is_subscribed) {
            this.createContentHistory(data)
        }
    }

    createContentHistory = (data) => {

        try {
            urlSocket.post('content_info/create-content-history', data).then((response) => {
                // //(response,'response')
            })

        } catch (error) {

        }



    }





    getText(html) {
        var divContainer = document.createElement("div");
        divContainer.innerHTML = html
        let data = divContainer.textContent || divContainer.innerText || ""
        data = data.substring(0, 200) + '...'
        return data
    }



    handleAddToCart = _debounce((selected_course, annual_plan, index1, index2, index3) => {
        if(!this.state.addingCart){
        try {
            this.setState({ btn_loader: true, index1: null, index2: null, index3: null,addingCart: true,  dataloaded: true }, () => {
                selected_course["course_price"] = annual_plan
                urlSocket.post('customer_basket/add-to-customer-cart', {
                    user_id: this.state.isLogedUser._id,
                    course: selected_course, annual_plan,
                })
                    .then((res) => {
                        if (res.data.response_code === 500) {
                            this.setState({ index1: index1, index2: index2, index3: index3, axiosTimeout: false }, () => {
                                if (this.state.categoryType !== "" && this.state.artistType !== "" && this.state.courseType !== "") {
                                    this.onFilterCategory(this.state.categoryType)
                                }
                                else if (this.state.categoryType !== "" && this.state.artistType !== "") {
                                    this.onFilterCategory(this.state.categoryType)
                                }
                                else if (this.state.artistType !== "" && this.state.courseType !== "") {
                                    this.onFilterArtist(this.state.artistType)
                                }
                                else if (this.state.categoryType !== "") {
                                    this.onFilterCategory(this.state.categoryType)
                                }
                                else if (this.state.artistType !== "") {
                                    this.onFilterArtist(this.state.artistType)
                                }
                                else if (this.state.courseType !== "") {
                                    this.onFilterCourse(this.state.courseTypeId)
                                }
                                else {
                                    this.getSUCourses()
                                }
                            })
                        }

                    })
                    .catch((error) => {
                        this.setState({
                            axiosTimeout: true,
                            dataloaded: false
                        })
                    })
                    .finally(() => {
                        setTimeout(() => {
                          this.setState({ addingCart: false });
                        }, 1000);
                      });
            })
        }
        catch (error) {
            this.setState({
                axiosTimeout: true
            })
        }
        }
    },100)

    handleRemoveFromCart = _debounce((selected_course, annual_plan, index1, index2, index3) => {
        if(!this.state.removeCart){
        try {
            this.setState({ btn_loader: true, index1: null, index2: null, index3: null,removeCart: true, dataloaded: true }, () => {
                if (selected_course.added_cart === true) {

                    selected_course["course_price"] = annual_plan
                    urlSocket.post('customer_basket/remove-from-customer-cart', {
                        user_id: this.state.isLogedUser._id,
                        course_id: selected_course._id,
                        session_id: selected_course.basket_session_id
                    })
                        .then((response) => {
                            if (response.data.response_code === 500) {
                                this.setState({ index1: index1, index2: index2, index3: index3, axiosTimeout: false }, () => {
                                    if (this.state.categoryType !== "" && this.state.artistType !== "" && this.state.courseType !== "") {
                                        this.onFilterCategory(this.state.categoryType)
                                    }
                                    else if (this.state.categoryType !== "" && this.state.artistType !== "") {
                                        this.onFilterCategory(this.state.categoryType)
                                    }
                                    else if (this.state.artistType !== "" && this.state.courseType !== "") {
                                        this.onFilterArtist(this.state.artistType)
                                    }
                                    else if (this.state.categoryType !== "") {
                                        this.onFilterCategory(this.state.categoryType)
                                    }
                                    else if (this.state.artistType !== "") {
                                        this.onFilterArtist(this.state.artistType)
                                    }
                                    else if (this.state.courseType !== "") {
                                        this.onFilterCourse(this.state.courseTypeId)
                                    }
                                    else {
                                        this.getSUCourses()
                                    }
                                })

                            }

                        })
                        .catch((error) => {
                            this.setState({
                                axiosTimeout: true,
                                dataloaded: false
                            })
                        })
                        .finally(() => {
                            setTimeout(() => {
                              this.setState({ removeCart: false });
                            }, 1000);
                          });
                }
            })


        }
        catch (error) {
            this.setState({
                axiosTimeout: true,
                dataloaded: false
            })
        }
    }
    },100)

    onFilterCourse = (value) => {
        console.log(value,'value')
        this.setState({
            // courseType: value === "0" ? "" : value === "1" ? "combo" : value === "2" ? "single" : '',
            courseType: value === "0" ? "" : value === "1" || value === "3" ? "combo" : value === "2"  || value==="4" || value==="5" ? "single" :"",
            courseTypeId : value
        }, () => {
            try {
                urlSocket.post('mngsubcrs/search-course-list', {
                    filter_type: value,
                    artist_filter_type: this.state.artist_filter_type,
                    searchTerm: [this.state.courseType, this.state.artistType, this.state.categoryType],
                    page_type: 1, //api condtional
                    subscriber_id: this.state.isLogedUser._id,
                    value:this.state.courseTypeId
                }).then((response) => {
                    console.log(response,'response');
                    if (response.data.response_code === 500) {
                        let courseList = response.data.data
                        let catData = response.data.final_data
                        let cartCountLength = response.data.cart_count
                        if(courseList.length >0){
                        this.setState({ catData, su_courses: courseList, dataloaded: true, btn_loader: false, selected_course_filter: value,no_course_available : false })
                        this.props.dispatch(setCartCourseListLength(cartCountLength));  
                    }
                        else{
                            this.setState({ no_course_available : true,catData, su_courses: courseList, dataloaded: true, btn_loader: false, selected_course_filter: value })
                        }
                    }
                })
                    .catch((error) => {
                        this.setState({
                            axiosTimeout: true
                        })
                    })
            } catch (error) {
                this.setState({
                    axiosTimeout: true
                })
            }
        })
    }

    onFilterArtist = (value) => {
        this.setState({
            artistType: value === "All" ? '' : value,
        }, () => {
            try {
                urlSocket.post('mngsubcrs/search-course-list', {
                    filter_type: this.state.selected_course_filter,
                    artist_filter_type: value,
                    searchTerm: [this.state.courseType, this.state.artistType, this.state.categoryType],
                    page_type: 1, //api condtional
                    subscriber_id: this.state.isLogedUser._id,
                    // value:this.state.courseTypeId
                    value:"1"

                }).then((response) => {
                    if (response.data.response_code === 500) {
                        let courseList = response.data.data
                        let catData = response.data.final_data
                        let cartCountLength = response.data.cart_count
                        if(courseList.length >0){
                            this.props.dispatch(setCartCourseListLength(cartCountLength));  
                        this.setState({ catData, su_courses: courseList, dataloaded: true, btn_loader: false, artist_filter_type: value,no_course_available : false })
                        }
                        else{
                            this.setState({ catData, su_courses: courseList, dataloaded: true, btn_loader: false, artist_filter_type: value,no_course_available : true })
                        }

                    }
                })
                    .catch((error) => {
                        this.setState({
                            axiosTimeout: true
                        })
                    })
            } catch (error) {
                this.setState({
                    axiosTimeout: true
                })
            }
        })
    }

    onFilterCategory = (value) => {
        console.log(value,'value');
        this.setState({
            categoryType: value === "All" ? '' : value,
            // courseTypeId : value
        }, () => {
            console.log(this.state.courseTypeId,'this.state.courseTypeId');
            try {
                urlSocket.post('mngsubcrs/search-course-list', {
                    filter_type: this.state.selected_course_filter,
                    artist_filter_type: value,
                    searchTerm: [this.state.courseType, this.state.artistType, this.state.categoryType],
                    page_type: 1, //api condtional
                    subscriber_id: this.state.isLogedUser._id,
                    value:"1"
                }).then((response) => {
                    if (response.data.response_code === 500) {
                        let courseList = response.data.data
                        let catData = response.data.final_data
                        let cartCountLength = response.data.cart_count
                        if(courseList.length >0){
                            this.props.dispatch(setCartCourseListLength(cartCountLength));  
                        this.setState({ catData, su_courses: courseList, dataloaded: true, btn_loader: false, artist_filter_type: value, no_course_available : false })
                        }
                        else{
                            this.setState({ catData, su_courses: courseList, dataloaded: true, btn_loader: false, artist_filter_type: value ,no_course_available : true})
                        }
                    }
                })
                    .catch((error) => {
                        this.setState({
                            axiosTimeout: true
                        })
                    })
            } catch (error) {
                this.setState({
                    axiosTimeout: true
                })
            }
        })
    }


    resetAllCourse=()=>{
        this.categoryList.current.value = "All";
        this.artistList.current.value = "All";
        this.courseType.current.value ="0"
        this.setState({dataloaded : false , courseType :"" , artistType:'',categoryType:'',courseTypeId:''},()=>{
            this.setState({
                dataloaded: true
            },()=>{
              this.onFilterCourse(this.courseType.current.value)  
            })
        })
    }




    render() {
        if (this.state.dataloaded === true) {
            return (
                <React.Fragment>
                    <div className="page-content homepage" style={{marginTop:0}}>
                        <MetaTags>
                            <title>Kalakriya-Bharatanatyam Video Courses online adavus, kalakshetra bani - Slokam Mandalas Hastas & Adavus, Alarippu, Jathiswaram, Shabdam, Varnam & Keerthanam, hastas, bhedas,Pushpanjali, Alarippu, Kavuthuvam, Thodayamangalam & Mallari, Navasandhi Kavuthuvam, Jathiswaram, Sabdam, Varnam video, Varnam & Jathis audio, bharatanatyam Abhinaya, Navarasa by Kalanidhi Narayanan, padams and javalis, thillana by priyadarsini govind, margam by priyadarsini govind, nattuvangam</title>
                        </MetaTags>
                        <div className="homepageContainer">

                            <BannerSwiperLG />
                            {/* <div className="d-md-block d-lg-none"><BannerSwiperMD /></div> */}

                            <Container fluid style={{ padding: 0, margin: "0 auto" }}>
                                {
                                    this.state.subscribedCourses?.length > 0 &&
                                    <div className="py-3 mb-4 w-100 "
                                        style={{
                                            borderRight: "1px solid #dedede",
                                            backgroundImage: `url(${img1})`,
                                            backgroundRepeat: "no-repeat",
                                            backgroundOrigin: "border-box",
                                            backgroundPosition: "center bottom",
                                            backgroundSize: 500,
                                            backgroundColor: "rgba(227,187,211,0.5)"
                                        }}
                                    >
                                        <div className="my-2 container">
                                            <div className="fw-bold text-secondary font-size-18">Subscribed Courses</div>
                                        </div>

                                        <div className="container my-1" >
                                            <Carousel
                                                ssr
                                                deviceType={"desktop"}
                                                draggable={true}
                                                infinite={false}
                                                responsive={responsive}
                                                dotListClass="custom-dot-list-style"
                                                itemClass="carousel-item-padding-40-px"
                                                containerClass="carousel-container"
                                                showDots={true}
                                            >
                                                {
                                                    this.state.subscribedCourses.map((data, index) => {
                                                        return (
                                                            <SubscribedCard
                                                                key={"srbcard" + index}
                                                                selected_course={data}
                                                                isLogedUser={this.state.isLogedUser}
                                                                baseurl={this.state.baseurl}
                                                                description={this.getText(data.description_of_the_course)}
                                                                viewCourse={() => { this.courseDetails(data) }}
                                                            />
                                                        )
                                                    }
                                                    )

                                                }
                                                {
                                                    this.state.subscribedCourses.length === 9 &&
                                                    <MoreViewCard
                                                        toViewMore={() => {
                                                            this.props.history.push("/my-courses")
                                                        }}
                                                    />
                                                }
                                            </Carousel>
                                        </div>
                                    </div>
                                }


                                <div className="container mt-3" >
                                <div className=" mb-2 pb-2" style={{ borderBottom: "1px solid #dedede" }}>
                                <h3>Welcome to Kalakriya Series!</h3>
                                <span>Unlock unlimited access to premium content with our subscription service. Enjoy seamless streaming without the hassle of downloads. Join today and elevate your experience!</span>
                                <br/>
                                <br/>

                                    <div className="d-flex flex-column flex-md-row align-items-start align-item-md-center justify-content-between" style={{ width: '100%', }} >
                                        <div className="mb-3 mb-md-0 col-auto" >
                                            <ul className="nav nav-pills gap-2">
                                                <NavItem>
                                                    <div className="form-group" style={{margin:"0 !important"}}>
                                                        <Input type="select" id="course-dropdown" onChange={(e) => this.onFilterCourse(e.target.value)}>
                                                            <option  ref={this.courseType} value="0">All Course Types</option>
                                                            <option value="1">Combo Courses</option>
                                                            {/* <option value="2">Single Courses</option> */}
                                                            {/* <option value="3">Combo Course - Video/Audio</option> */}
                                                            <option value="4">Single Course - Video</option>
                                                            <option value="5">Single Course - Audio</option>
                                                        </Input>
                                                    </div>
                                                </NavItem>
                                                <NavItem>
                                                <div className="form-group" style={{margin:"0 !important"}}>
                                                        <Input  type="select" id="course-dropdown" onChange={(e) => this.onFilterCategory(e.target.value)}>
                                                            <option ref={this.categoryList} value="All">All Categories</option>
                                                            {
                                                                this.state.categoryList.map((ele, idx) => {
                                                                    return (
                                                                        <option key={idx} value={ele.category}>{ele.category}</option>
                                                                    )
                                                                })
                                                            }
                                                        </Input>
                                                    </div>
                                                </NavItem>
                                                <NavItem>
                                                <div className="form-group" style={{margin:"0 !important"}}>
                                                        <Input  type="select" id="course-dropdown" onChange={(e) => this.onFilterArtist(e.target.value)}>
                                                            <option value="All"  ref={this.artistList}>All Artists</option>
                                                            {
                                                                this.state.artistList.map((ele, idx) => {
                                                                    return (
                                                                        <option key={idx} value={ele.artist_name}>{ele.artist_name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </Input>
                                                    </div>
                                                </NavItem>
                                                    <NavItem>
                                                    <div className="form-group" style={{margin:"0 !important"}}>
                                                        <button className="btn btn-outline" onClick={() => {
                                                            this.resetAllCourse()
                                                        }}>Reset</button>
                                                        </div>
                                                    </NavItem>
                                            </ul>
                                        </div>
                                        {/* <div>
                                            <button className="btn btn-primary" onClick={()=>{
                                                this.resetAllCourse()
                                            }}>Reset</button>
                                        </div> */}
                                        <div className="col-auto">
                                            <ul className="nav nav-pills">
                                                <NavItem>
                                                    <NavLink
                                                        className="disabled"
                                                        to="/#"
                                                        tabIndex="-1"
                                                    >
                                                        View :
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <Link
                                                        to="#"
                                                        className={this.state.gridView === "block" ? "nav-link active" : "nav-link"}
                                                        onClick={() => this.setState({ gridView: "block" })}
                                                    >
                                                        <i className="mdi mdi-view-grid-outline"></i>
                                                    </Link>
                                                </NavItem>
                                                <NavItem>
                                                    <Link
                                                        className={this.state.gridView === "list" ? "nav-link active" : "nav-link"}
                                                        to="#"
                                                        onClick={() => this.setState({ gridView: "list" })}

                                                    >
                                                        <i className="mdi mdi-format-list-bulleted"></i>
                                                    </Link>
                                                </NavItem>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                    {
                                        this.state.gridView === "list" && this.state.catData.length > 0 && this.state.su_courses.length >0 ?
                                            <>
                                                {this.state.catData.map((item, index) => {
                                                    
                                                    if (item.content.length > 0) {
                                                          <h4 style={{ fontWeight: 900 }}>{item.cat}</h4>
                                                          return item.content.map((elem, didx) => (
                                                            <Row key={index} className="mx-2 mb-4 d-flex justify-content-center justify-content-lg-start" style={{ borderBottom: index < this.state.cardCourseList.length - 1 ? "1px solid #dedede" : "none" }}>
                                                                <h4 style={{ fontWeight: 900 }}>{elem.category}</h4>
                                                                {elem.courses.map((data, dataIndex) => (
                                                                    <Col key={dataIndex + "crs"} xxl="3" xl="4" lg="4" md="5" sm="6" className="g-1"  >
                                                                        <HomeCourseCard
                                                                            selected_course={data}
                                                                            isLogedUser={this.state.subscriber_info}
                                                                            baseurl={this.state.baseurl}
                                                                            description={this.getText(data.description_of_the_course)}
                                                                            handleAddToCart={() => { this.handleAddToCart(data, data.crs_subscptn_plans.find(plan => plan.crs_subscptn_plan === 'Annually')?.inr_price, index, didx, dataIndex) }}
                                                                            removeFromCart={() => { this.handleRemoveFromCart(data, data.crs_subscptn_plans.find(plan => plan.crs_subscptn_plan === 'Annually')?.inr_price, index, didx, dataIndex) }}
                                                                            viewCourse={() => { this.courseDetails(data) }}
                                                                            btn_loader={this.state.index1 === index && this.state.index2 === didx && this.state.index3 === dataIndex ? this.state.btn_loader : false}
                                                                        />
                                                                    </Col>
                                                                ))}
                                                            </Row>
                                                        ))                                                        
                                                    }
                                                    else {
                                                        <>
                                                            {
                                                                this.state.catData.length === 1 &&
                                                                <div>
                                                                    <label>No Courses Available</label>
                                                                </div>
                                                            }
                                                        </>
                                                    }
                                                })}
                                            </>
                                            : this.state.gridView === "block" && this.state.catData.length > 0 && this.state.su_courses.length >0?
                                                <>
                                                    {
                                                        this.state.catData && this.state.catData.map((item, idx) => {
                                                                if (item.content.length > 0) {
                                                                    return (
                                                                        <div key={idx + "cd"}>
                                                                            <p className="font-size-20 text-center" style={{ fontWeight: 900 }}>{item.cat}</p>
                                                                            <Row className="mx-2 mb-2 d-flex justify-content-center justify-content-lg-start">
                                                                                {item.content.map((elem, didx) => (
                                                                                    elem.courses.map((data, dataIndex) => (
                                                                                        <Col key={dataIndex} xxl="3" xl="4" lg="4" md="5" sm="6" className="g-1">
                                                                                            <HomeCourseCard
                                                                                                selected_course={data}
                                                                                                isLogedUser={this.state.subscriber_info}
                                                                                                baseurl={this.state.baseurl}
                                                                                                description={this.getText(data.description_of_the_course)}
                                                                                                handleAddToCart={() => { this.handleAddToCart(data, data.crs_subscptn_plans.find(plan => plan.crs_subscptn_plan === 'Annually')?.inr_price, idx, didx, dataIndex) }}
                                                                                                removeFromCart={() => { this.handleRemoveFromCart(data, data.crs_subscptn_plans.find(plan => plan.crs_subscptn_plan === 'Annually')?.inr_price, idx, didx, dataIndex) }}
                                                                                                viewCourse={() => { this.courseDetails(data) }}
                                                                                                btn_loader={(this.state.index1 === idx) && (this.state.index2 === didx) && (this.state.index3 === dataIndex) ? this.state.btn_loader : false}
                                                                                            />
                                                                                        </Col>
                                                                                    ))
                                                                                ))}
                                                                            </Row>
                                                                        </div>
                                                                    );
                                                                }
                                                            // if (item.content.length > 0) {
                                                            //     return <Row key={idx + "cd"} className="mx-2 mb-2 d-flex justify-content-center justify-content-lg-start">
                                                            //         <Row className="mx-2 mb-2 d-flex justify-content-center justify-content-lg-start">
                                                            //             {
                                                            //                 item.content.map((elem, didx) => (
                                                            //                     elem.courses.map((data, dataIndex) => {
                                                            //                         return <Col key={dataIndex} xxl="3" xl="4" lg="4" md="5" sm="6" className="g-1"  >
                                                            //                             <HomeCourseCard
                                                            //                                 selected_course={data}
                                                            //                                 isLogedUser={this.state.subscriber_info}
                                                            //                                 baseurl={this.state.baseurl}
                                                            //                                 description={this.getText(data.description_of_the_course)}
                                                            //                                 handleAddToCart={() => { this.handleAddToCart(data, data.crs_subscptn_plans.find(plan => plan.crs_subscptn_plan === 'Annually')?.inr_price, idx, didx, dataIndex) }}
                                                            //                                 removeFromCart={() => { this.handleRemoveFromCart(data, data.crs_subscptn_plans.find(plan => plan.crs_subscptn_plan === 'Annually')?.inr_price, idx, didx, dataIndex) }}
                                                            //                                 viewCourse={() => { this.courseDetails(data) }}
                                                            //                                 btn_loader={(this.state.index1 === idx) && (this.state.index2 === didx) && (this.state.index3 === dataIndex) ? this.state.btn_loader : false}

                                                            //                             />
                                                            //                             {/* <ShareButtons course_info={data} shareUrl={`http://localhost.com:3000/course/CourseDetails?${data._id}?${data.name_of_the_course}`} /> */}
                                                            //                         </Col>
                                                            //                     })
                                                            //                 ))
                                                            //             }
                                                            //         </Row>
                                                            //     </Row>
                                                            // }
                                                            else {
                                                                return (
                                                                    <>
                                                                        {
                                                                            this.state.catData.length === 1 &&
                                                                            <div>
                                                                                <label>No Courses Available</label>
                                                                            </div>
                                                                        }
                                                                    </>
                                                                )
                                                            }
                                                        })
                                                    }
                                                </> :
                                                <>
                                                    {/* <div class="centered-div text-primary">
                                                        <label>No Subscribed Courses Available</label>
                                                    </div> */}
                                                    <div>
                                                        <label>No Courses Available</label>
                                                    </div>
                                                </>
                                    }
                                </div>
                            </Container>
                        </div>
                    </div>
                </React.Fragment>
            )
        } else
            if (this.state.axiosTimeout) {
                return <React.Fragment>
                    <div className="page-content" style={{ marginTop: 0 }}  >
                        <Container fluid >
                            <Row style={{ minHeight: "calc(100vh - 450px)" }}>
                                <div className='d-flex w-100 flex-column justify-content-center align-items-center'>
                                    <div>Something went wrong. Please try again after sometime.</div>
                                </div>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>
            }
            else {
                return <React.Fragment>
                    <div className="page-content" style={{ marginTop: 0 }}  >
                        <Container fluid >
                            <Row style={{ minHeight: "calc(100vh - 450px)" }}>
                                <div className='d-flex w-100 flex-column justify-content-center align-items-center'>
                                    <Spinner className="mb-2" color="primary" />
                                    <div>Loading ...</div>
                                </div>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>
            }
    }
}


const mapStateToProps = (state) => {
    return {
        layoutType: state.Layout.layoutType,
        cartCourseListLength: state.Layout.cartCourseListLength,
        searchTerm: state.Layout.searchTerm,
        SearchCourseList: state.Layout.SearchCourseList
    };
};

export default connect(mapStateToProps)(Dashboard);




