import React, { Component, useState, useEffect, useCallback, useRef } from "react";
import { Container, NavItem, NavLink, Row, Col, Spinner, FormGroup, Label, Input } from "reactstrap"
import { withRouter, Link } from "react-router-dom"
import MetaTags from 'react-meta-tags';
import urlSocket from '../../helpers/urlSocket'
import Select from 'react-select'
import _, { isNull } from 'lodash'

// Import Swiper styles
import '../../../node_modules/swiper/swiper-bundle.css';
// import { Button, Col, PaginationItem, PaginationLink, Row } from "reactstrap"

//Banner Swiper
import BannerSwiperLG from "../Common/Carousel/components/banner-swiper";
import BannerSwiperMD from "../Common/Carousel/components/banner-swiper-md";
//Overlap Swiper

// import './style.css'
import SortableTree, { getFlatDataFromTree } from 'react-sortable-tree/dist/index.cjs.js'
// import CourseCard from "pages/Landing/Component/CourseCard";
import HomeCourseCard from "../CommonComponents/CourseCard";
// import ShareButtons from "common/ShareButton";







const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 5,
    },
    laptop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
    }
}



class Home extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data_loaded: false,
            carousel_info: [],
            baseurl: "",
            selected_artists_name: "",
            selected_course: [],
            coupon_codes: [],
            cardCourseList: [],
            gridView: "block", artist_filter_type: "All",
            selected_course_filter: '0',
            artistList: [],
            categoryList: [],
            selected_course_filter: '0',
            courseType: '',
            courseTypeId:"",
            artistType: '',
            categoryType: ''
        };
        this.categoryList = React.createRef()
        this.artistList = React.createRef()
        this.courseType = React.createRef()
    }


    componentDidMount = async () => {
        // if(JSON.parse(sessionStorage.getItem('authUser'))){
        //     this.props.history.push("/dashboard")
        // }else{
        // localStorage.removeItem("CourseDetails")
        // localStorage.removeItem('express-session')
        await this.setSession()
        await this.getAllCourseList()
        // }

    }

    setSession = async () => {
        try {
            urlSocket.post('ctlibmgt/getconf')
                .then((res) => {
                    let secret_key = res.data.data.secret_key
                    this.setState({ secret_key })
                    let data = res.data.data
                    let baseurl = data.baseurl

                    this.setState({ baseurl })

                    sessionStorage.setItem("getconfsession", JSON.stringify(data))

                })
                .catch((error) => {
                    // console.log("error", error)
                })
        } catch (error) {
            // console.log("catch error", error)
        }
    }


    onFilterCourse = (value) => {
        // console.log(value, 'value')
        this.setState({
            courseType: value === "0" ? "" : value === "1" || value === "3" ? "combo" : value === "2"  || value==="4" || value==="5" ? "single" :"",
            courseTypeId : value

        }, () => {
            try {
                urlSocket.post('mngsubcrs/search-course-list', {
                    filter_type: value,
                    artist_filter_type: this.state.artist_filter_type,
                    searchTerm: [this.state.courseType, this.state.artistType, this.state.categoryType],
                    page_type: 1, //api condtional
                    subscriber_id: null,
                    value:value
                }).then((response) => {
                    // console.log(response, 'response')
                    if (response.data.response_code === 500) {
                        let courseList = response.data.data
                        let catData = response.data.final_data
                        let cartCountLength = response.data.cart_count
                        if (courseList.length > 0) {
                            // if (courseList.length > 0) {
                            this.setState({ catData, su_courses: courseList, dataloaded: true, selected_course_filter: value })
                            // this.props.dispatch(setCartCourseListLength(cartCountLength));
                        }
                        else {
                            this.setState({ catData, su_courses: courseList, dataloaded: true, selected_course_filter: value })
                        }
                        // }
                    }
                })
                    .catch((error) => {
                        // console.log("then catch error", error)
                        this.setState({
                            axiosTimeout: true
                        })
                    })
            } catch (error) {
                // console.log("catch error", error)
                this.setState({
                    axiosTimeout: true
                })


            }
        })
    }


    onFilterArtist = (value) => {
        // console.log(value, 'value')
        this.setState({
            artistType: value === "All" ? '' : value,
        }, () => {
            try {
                urlSocket.post('mngsubcrs/search-course-list', {
                    filter_type: this.state.selected_course_filter,
                    artist_filter_type: value,
                    searchTerm: [this.state.courseType, this.state.artistType, this.state.categoryType],
                    page_type: 1, //api condtional
                    subscriber_id: null,
                    value:"1"
                }).then((response) => {
                    // console.log(response, 'response')
                    if (response.data.response_code === 500) {
                        let courseList = response.data.data
                        let catData = response.data.final_data
                        let cartCountLength = response.data.cart_count

                        if (courseList.length > 0) {
                            this.setState({ catData, su_courses: courseList, dataloaded: true, artist_filter_type: value })
                            // this.props.dispatch(setCartCourseListLength(cartCountLength));
                        }
                        else {
                            this.setState({ catData, su_courses: courseList, dataloaded: true, artist_filter_type: value })
                        }
                    }
                })
                    .catch((error) => {
                        // console.log("then catch error", error)
                        this.setState({
                            axiosTimeout: true
                        })
                    })
            } catch (error) {
                // console.log("catch error", error)
                this.setState({
                    axiosTimeout: true
                })
            }
        })
    }





    getAllCourseList = async () => {
        this.setState({
            axiosTimeout: false
        })

        var subscriber_info = {}
        if (sessionStorage.getItem("authUser")) {
            subscriber_info = JSON.parse(sessionStorage.getItem("authUser"))
        }

        try {
            urlSocket.post('mngsubcrs/retrive-all-courses', { subscriber_id: subscriber_info._id })
                .then((response) => {
                    if (response.data.response_code === 500) {
                        let courseList = response.data.data
                        let catData = response.data.final_data
                        var artistList = response.data.artist_list
                        var categoryList = response.data.category
                        if (courseList.length > 0) {
                            this.setState({ catData, su_courses: courseList, artistList, dataloaded: true, categoryList })
                        }
                    }
                })
                .catch((error) => {
                    // console.log("then catch error", error.code)
                    this.setState({
                        axiosTimeout: true
                    })
                })
        } catch (error) {
            // console.log("catch error", error)
            this.setState({
                axiosTimeout: true
            })
        }
    }


    treeDataToFlat = (treeData) => {
        var getNodeKey = ({ treeIndex }) => treeIndex
        var flatData = getFlatDataFromTree({ treeData: treeData, getNodeKey, ignoreCollapsed: false })
        var child_data = flatData.filter(item2 => item2.node.parent_id !== null)
        return child_data.length
    }

    convertDate = (date) => {
        var today = new Date(date)
        var dd = String(today.getDate()).padStart(2, '0')
        var mm = String(today.getMonth()) //January is 0!
        var yyyy = today.getFullYear()
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        today = dd + ' ' + months[mm] + ' ' + yyyy

        return today
    }
    getText(html) {
        var divContainer = document.createElement("div");
        divContainer.innerHTML = html
        let data = divContainer.textContent || divContainer.innerText || ""
        data = data.substring(0, 200) + '...'
        return data
    }

    courseDetails = (data) => {
        localStorage.setItem("CourseDetails", JSON.stringify({ _id: data._id, name_of_the_course: data.name_of_the_course }))
        this.props.history.push("/login")
    }

    onFilterCategory = (value) => {
        this.setState({
            categoryType: value === "All" ? '' : value
        }, () => {
            try {
                urlSocket.post('mngsubcrs/search-course-list', {
                    filter_type: this.state.selected_course_filter,
                    artist_filter_type: value,
                    searchTerm: [this.state.courseType, this.state.artistType, this.state.categoryType],
                    page_type: 1, //api condtional
                    subscriber_id: null,
                    //  value:this.state.courseTypeId
                    value:"1"
                }).then((response) => {
                    // console.log(response, 'response')
                    if (response.data.response_code === 500) {
                        let courseList = response.data.data
                        let catData = response.data.final_data
                        let cartCountLength = response.data.cart_count

                        if (courseList.length > 0) {
                            this.setState({ catData, su_courses: courseList, dataloaded: true, artist_filter_type: value })
                            // this.props.dispatch(setCartCourseListLength(cartCountLength));
                        }
                        else {
                            this.setState({ catData, su_courses: courseList, dataloaded: true, artist_filter_type: value })
                        }
                    }
                })
                    .catch((error) => {
                        // console.log("then catch error", error)
                        this.setState({
                            axiosTimeout: true
                        })
                    })
            } catch (error) {
                // console.log("catch error", error)
                this.setState({
                    axiosTimeout: true
                })
            }
        })


    }


    resetAllCourse = () => {
        this.categoryList.current.value = "All";
        this.artistList.current.value = "All";
        this.courseType.current.value = "0"
        this.setState({ dataloaded: false, courseType: "",courseTypeId :"", artistType: '', categoryType: '' }, () => {
            this.setState({
                dataloaded: true
            }, () => {
                this.onFilterCourse(this.courseType.current.value)
            })
        })

    }



    render() {
        if (this.state.dataloaded === true) {

            return (
                <React.Fragment>
                    <div className="page-content homepage">
                        <MetaTags>
                            {/* <title>Kalakriya</title> */}
                            <title>Kalakriya - Bharatanatyam Video Courses online adavus, kalakshetra bani - Slokam Mandalas Hastas & Adavus, Alarippu, Jathiswaram, Shabdam, Varnam & Keerthanam, hastas, bhedas,Pushpanjali, Alarippu, Kavuthuvam, Thodayamangalam & Mallari, Navasandhi Kavuthuvam, Jathiswaram, Sabdam, Varnam video, Varnam & Jathis audio, bharatanatyam Abhinaya, Navarasa by Kalanidhi Narayanan, padams and javalis, thillana by priyadarsini govind, margam by priyadarsini govind, nattuvangam</title>
                        </MetaTags>
                        <div className="">
                            <BannerSwiperLG />
                            <Container className="mt-3">

                                <div className=" mb-2 pb-2" style={{ borderBottom: "1px solid #dedede" }}>
                                <h3>Welcome to Kalakriya Series!</h3>
                                <span>Unlock unlimited access to premium content with our subscription service. Enjoy seamless streaming without the hassle of downloads. Join today and elevate your experience!</span>
                                <br/>
                                <br/>
                                    <div className="d-flex flex-column flex-md-row align-items-start align-item-md-center justify-content-between" style={{ width: '100%', }} >
                                        <div className="mb-3 mb-md-0 col-auto" >
                                            <ul className="nav nav-pills gap-2">
                                                <NavItem>
                                                    <div className="form-group" style={{ margin: "0 !important" }}>
                                                        <Input type="select" id="course-dropdown" onChange={(e) => this.onFilterCourse(e.target.value)}>
                                                            <option ref={this.courseType} value="0">All Course Types</option>
                                                            <option value="1">Combo Courses</option>
                                                            {/* <option value="2">Single Courses</option> */}
                                                            {/* <option value="3">Combo Course - Video/Audio</option> */}
                                                            <option value="4">Single Course - Video</option>
                                                            <option value="5">Single Course - Audio</option>



                                                        </Input>
                                                    </div>
                                                </NavItem>
                                                <NavItem>
                                                    <div className="form-group" style={{ margin: "0 !important" }}>
                                                        <Input type="select" id="course-dropdown" onChange={(e) => this.onFilterCategory(e.target.value)}>
                                                            <option ref={this.categoryList} value="All">All Categories</option>
                                                            {
                                                                this.state.categoryList.map((ele, idx) => {
                                                                    return (
                                                                        <option key={idx} value={ele.category}>{ele.category}</option>
                                                                    )
                                                                })
                                                            }
                                                        </Input>
                                                    </div>
                                                </NavItem>
                                                <NavItem>
                                                    <div className="form-group" style={{ margin: "0 !important" }}>
                                                        <Input type="select" id="course-dropdown" onChange={(e) => this.onFilterArtist(e.target.value)}>
                                                            <option value="All" ref={this.artistList}>All Artists</option>
                                                            {
                                                                this.state.artistList.map((ele, idx) => {
                                                                    return (
                                                                        <option key={idx} value={ele.artist_name}>{ele.artist_name}</option>
                                                                    )
                                                                })
                                                            }
                                                        </Input>
                                                    </div>
                                                </NavItem>
                                                <NavItem>
                                                    <div className="form-group" style={{ margin: "0 !important" }}>
                                                        <button className="btn btn-outline" onClick={() => {
                                                            this.resetAllCourse()
                                                        }}>Reset</button>
                                                    </div>
                                                </NavItem>
                                            </ul>
                                        </div>
                                        <div className="col-auto">
                                            <ul className="nav nav-pills">
                                                <NavItem>
                                                    <NavLink
                                                        className="disabled"
                                                        to="/#"
                                                        tabIndex="-1"
                                                    >
                                                        View :
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <Link
                                                        to="#"
                                                        className={this.state.gridView === "block" ? "nav-link active" : "nav-link"}
                                                        onClick={() => this.setState({ gridView: "block" })}
                                                    >
                                                        <i className="mdi mdi-view-grid-outline"></i>
                                                    </Link>
                                                </NavItem>
                                                <NavItem>
                                                    <Link
                                                        className={this.state.gridView === "list" ? "nav-link active" : "nav-link"}
                                                        to="#"
                                                        onClick={() => this.setState({ gridView: "list" })}

                                                    >
                                                        <i className="mdi mdi-format-list-bulleted"></i>
                                                    </Link>
                                                </NavItem>

                                            </ul>
                                        </div>
                                    </div>
                                </div>



                                {
                                    this.state.gridView === "list" && this.state.catData.length > 0 && this.state.su_courses.length > 0 ?
                                        <>
                                            {this.state.catData.map((item, index) => {

                                                if (item.content.length > 0) {

                                                    return item.content.map((elem, didx) => (

                                                        <Row key={index} className="mx-2 mb-4 d-flex justify-content-center justify-content-lg-start" style={{ borderBottom: index < this.state.cardCourseList.length - 1 ? "1px solid #dedede" : "none" }}>
                                                            <h4 style={{ fontWeight: 900 }}>{elem.category}</h4>
                                                            {elem.courses.map((data, dataIndex) => (
                                                                <Col key={dataIndex + "crs"} xxl="3" xl="4" lg="4" md="5" sm="6" className="g-1"  >
                                                                    <HomeCourseCard
                                                                        selected_course={data}
                                                                        isLogedUser={this.state.subscriber_info}
                                                                        baseurl={this.state.baseurl}
                                                                        description={this.getText(data.description_of_the_course)}
                                                                        viewCourse={() => { this.courseDetails(data) }}
                                                                        handleAddToCart={() => { this.courseDetails(data) }}
                                                                    />
                                                                </Col>
                                                            ))}
                                                        </Row>
                                                    ))
                                                }
                                            })}
                                        </>
                                        : this.state.gridView === "block" && this.state.catData.length > 0 && this.state.su_courses.length > 0 ?
                                            <>
                                                {
                                                    this.state.catData && this.state.catData.map((item, idx) => {
                                                        if (item.content.length > 0) {
                                                            return (
                                                                <div key={idx + "cd"}>
                                                                    <p className="font-size-20 text-center" style={{ fontWeight: 900 }}>{item.cat}</p>

                                                                    <Row key={idx + "cd"} className="mx-2 mb-2 d-flex justify-content-center justify-content-lg-start">
                                                                        <Row className="mx-2 mb-2 d-flex justify-content-center justify-content-lg-start">
                                                                            {
                                                                                item.content.map((elem, didx) => (

                                                                                    elem.courses.map((data, dataIndex) => {
                                                                                        return <Col key={dataIndex} xxl="3" xl="4" lg="4" md="5" sm="6" className="g-1"  >
                                                                                            <HomeCourseCard
                                                                                                selected_course={data}
                                                                                                isLogedUser={this.state.subscriber_info}
                                                                                                baseurl={this.state.baseurl}
                                                                                                description={this.getText(data.description_of_the_course)}
                                                                                                viewCourse={() => { this.courseDetails(data) }}
                                                                                                handleAddToCart={() => { this.courseDetails(data) }}

                                                                                            />
                                                                                        </Col>
                                                                                    })

                                                                                ))
                                                                            }

                                                                        </Row>

                                                                    </Row>
                                                                </div>
                                                            )
                                                        }
                                                    })
                                                }
                                            </> :
                                            <>
                                                <div>
                                                    <label>No Courses Available</label>
                                                </div>
                                            </>
                                }
                            </Container>
                        </div>
                    </div>

                </React.Fragment>
            )
        } else
            if (this.state.axiosTimeout) {
                return <React.Fragment>
                    <div className="page-content" style={{ marginTop: 0 }}  >
                        <Container fluid >
                            <Row style={{ minHeight: "calc(100vh - 450px)" }}>
                                <div className='d-flex w-100 flex-column justify-content-center align-items-center'>
                                    <div>Something went wrong. Please try again after sometime.</div>
                                </div>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>
            }
            else {
                return <React.Fragment>
                    <div className="page-content" style={{ marginTop: 0 }}  >
                        <Container fluid >
                            <Row style={{ minHeight: "calc(100vh - 450px)" }}>
                                <div className='d-flex w-100 flex-column justify-content-center align-items-center'>
                                    <Spinner className="mb-2" color="primary" />
                                    <div>Loading ...</div>
                                </div>
                            </Row>
                        </Container>
                    </div>
                </React.Fragment>
            }
    }

}

export default Home










